import { Redirect, Route } from "react-router-dom";
import SignUp from "../components/Authetication/SignUp";
import Login from "../components/Authetication/Login";

const AuthRoutes = () => {
  return (
    <>
      {" "}
      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <Route path="/signup" component={SignUp} exact />
      <Route path="/login" component={Login} exact />
    </>
  );
};

export default AuthRoutes;