import * as React from "react";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useMediaQuery } from "@mui/material";

export default function MaterialUIPickers({ id, register, errors }) {
  //{helperText, error, formValParams}
  const [value, setValue] = React.useState(dayjs(""));
  const largeScreen = useMediaQuery("(min-width: 600px)");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const age = getAge(value);
    console.log(age);
  },[value]);

  function getAge(dateString) {
    let age;
    let today = dayjs(new Date().toISOString().slice(0, 10));
    age = today.diff(dateString, "years");
    return age;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        {!largeScreen ? (
          <MobileDatePicker
            id={id}
            name='dob'
            label="Date of Birth"
            inputFormat="YYYY-MM-DD"
            value={value}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={errors.dob?.message}
                {...register("dob")}
                error={errors.dob ? true : false}
              />
            )}
          />
        ) : (
          <DesktopDatePicker
            id={id}
            label="Date of Birth"
            name='dob'
            inputFormat="YYYY-MM-DD"
            value={value}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={errors.dob?.message}
                {...register("dob")}
                error={errors.dob ? true : false}
              />
            )}
          />
        )}
        {/* <TimePicker
          label="Time"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <DateTimePicker
          label="Date&Time picker"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        /> */}
      </Stack>
    </LocalizationProvider>
  );
}
