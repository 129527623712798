import { Button, Typography, Box, Card, CardContent } from "@mui/material";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

function Index() {
  const router = useHistory();
  const DynamicBackgroundImage = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center center;
    backface-visibility: hidden;
    animation: change_bg_img 8s linear infinite 0s;
    animation-timing-function: ease-in-out;
    background-image: url("/images/3d/wallpaperflare.com_wallpaper.jpg");

    @keyframes change_bg_img {
      0% {
        background-image: url("/images/3d/wallpaperflare.com_wallpaper (5).jpg");
      }
      25% {
        background-image: url("/images/3d/wallpaperflare.com_wallpaper (1).jpg");
      }
      50% {
        background-image: url("/images/3d/wallpaperflare.com_wallpaper (2).jpg");
      }
      75% {
        background-image: url("/images/3d/wallpaperflare.com_wallpaper (3).jpg");
      }
      100% {
        background-image: url("/images/3d/wallpaperflare.com_wallpaper (4).jpg");
      }
      /* 100% {
        background-image: url("/images/3d/wallpaperflare.com_wallpaper (5).jpg");
      }
      100% {
        background-image: url("/images/3d/wallpaperflare.com_wallpaper (6).jpg");
      } */
    }
  `;

  return (
    <DynamicBackgroundImage>
      <Card sx={{ width: 500, height: "50vh", margin: "20px" }}>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h1">louixani</Typography>
          <Box sx={{ height: "100px", display: "inline-block" }}>
            <Typography variant="h6">
              Create an acccount for everything louixani
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{
              borderRadius: "25px",
              padding: "13px",
            }}
            onClick={() => {
              router.push("/signup");
            }}
          >
            Create an Account
          </Button>
          <Typography margin="10px">
            <Link to={{ pathname: "https://louixani.com" }} target="_blank">
              louixani &copy; 2023
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </DynamicBackgroundImage>
  );
}

export default Index;
