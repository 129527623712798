import {
  Card,
  CardContent,
  Grid,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Box,
  Checkbox,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import CreditCardForm from "../components/PaymentMethodForm/CreditCardForm";
import SBForm from "../components/PaymentMethodForm/SBForm";

function UserPaymentMethod({
  submitRef,
  setNext,
  nextButton,
  snack,
  username,
}) {
  const [value, setValue] = useState("SB");
  const [form, setForm] = useState(<></>);
  function triggerPaymentForm(e) {
    setValue(e.target.value);
    setPaymentDetails((paymentDetails.paymentMethod = value));
  }
  console.log(submitRef, "Refg");

  useEffect(() => {
    paymentForm(value);
  }, [value]);

  function paymentForm(method) {
    switch (method) {
      case "SB":
        setForm(
          <SBForm
            submitRef={submitRef}
            setNext={setNext}
            snack={snack}
            nextButton={nextButton}
            username={username}
            paymentDetails={paymentDetails}
            setPaymentDetails={setPaymentDetails}
          />
        );
        console.log("sb selected");
        break;

      case "CreditCard":
        setForm(
          <CreditCardForm
            submitRef={submitRef}
            setNext={setNext}
            nextButton={nextButton}
            snack={snack}
            username={username}
            paymentDetails={paymentDetails}
            setPaymentDetails={setPaymentDetails}
          />
        );
        console.log("mascar selected");
        break;
      // case "Visa":
      //   setForm(<VisaForm submitRef={submitRef} setNext={setNext} />);
      //   console.log("vis selected");
      // break;
      default:
        break;
    }
  }

  // The User Payment Method Selection And Details
  const [paymentDetails, setPaymentDetails] = useState({
    paymentMethod: "",
    details: {},
  });

  return (
    <Box
      position="relative"
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      sx={{
        width: {
          xs: 300,
          sm: 400,
          md: 700,
        },
      }}
    >
      <Box
        variant="div"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        <Typography variant="h5">Payment Method</Typography>
        <FormControl>
          {/* <FormLabel id="demo-radio-buttons-group-label">Label</FormLabel> */}
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="SB"
            name="radio-buttons-group"
            value={value}
            onChange={triggerPaymentForm}
          >
            <FormControlLabel
              value="SB"
              control={<Radio />}
              label="SB Wallet"
            />
            <FormControlLabel
              value="CreditCard"
              control={<Radio />}
              label="Credit Card"
            />
            {/* <FormControlLabel value="Visa" control={<Radio />} label="Visa" /> */}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "scroll",
        }}
      >
        {form}
      </Box>
    </Box>
  );
}

export default UserPaymentMethod;
