import styled from "@emotion/styled";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Button = styled.button`
  height: max-content;
  width: max-content;
  padding: 5px;
`;

export const FlexContainer = ({ flexDirection, height, width, children }) => {
  const FlexWrapper = styled.div`
    display: flex;
    height: ${height};
    width: ${width};
    justify-content: center;
    flex-direction: ${flexDirection};
    align-items: center;
    margin: auto;
  `;
  return (
    <FlexWrapper>{children}</FlexWrapper>
  )
};

export const FlexDiv = ({ flexDirection, height, width, children }) => {
    const FlexWrapper = styled.div`
      /* display: flex; */
      height: ${height};
      width: ${width};
      /* justify-content: center;
      flex-direction: ${flexDirection};
      align-items: center;
      margin: auto; */
    `;
    return (
      <FlexWrapper><div>{children}</div></FlexWrapper>
    )
  };
  

export const Border = ({
  children,
  borderRadius,
  borderColor,
  borderPixel,
  borderStyle,
}) => {
  const CustomBorder = styled.div`
    border: ${borderStyle} ${borderColor} ${borderPixel};
    border-radius: ${borderRadius};
  `;
  return <CustomBorder>{children}</CustomBorder>;
};
