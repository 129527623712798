import { Card, CardActions, CardContent, Grid } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Border } from "../../../styles";
import { UserAdvanceDetails } from "../../context/user/UserAdvanceDetail";
import PlansCard from "../components/PlansCard";

const tiers = [
  {
    title: "Free",
    price: "0",
    description: [
      "Includes Ads",
      "Access to Community",
      "Unlimited Streaming",
      "1GB Daily Download limit ",
      "Support",
    ],
    buttonText: "Select",
    buttonVariant: "outlined",
    value: "free",
  },
  {
    title: "Pro",
    subheader: "Most popular",
    price: "15",
    description: [
      "No Ads",
      "Access to Community",
      "Unlimited Streaming",
      "Unlimited Downloads 720p, 1200",
      "Support",
    ],
    buttonText: "Select",
    buttonVariant: "contained",
    value: "pro",
  },
  {
    title: "VIP",
    price: "30",
    description: [
      "No Ads",
      "Access to Community",
      "Unlimited Streaming",
      "Unlimited Downloads HD",
      "Support",
    ],
    buttonText: "Select",
    buttonVariant: "outlined",
    value: "vip",
  },
];

function UserMembershipPlan({
  submitRef,
  skipToEnd,
  setNext,
  userId,
  nextButton,
  snack,
}) {
  const [activePlan, setActivePlan] = useState();
  function selectPlan(index) {
    nextButton(false);
    setActivePlan(index);
  }

  async function setPlan(plan) {
    console.log(plan);
    switch (plan) {
      case 0:
        let res = await setUserPlan({
          userid: userId,
          plan: tiers[plan].title,
        });
        res == 400
          ? snack({
              mode: true,
              severity: "error",
              message: "An Error Occurred Try Again Later!",
            })
          : skipToEnd();
        return;
      case 1:
        let res1 = await setUserPlan({
          userid: userId,
          plan: tiers[plan].title,
        });
        res1 == 400
          ? snack({
              mode: true,
              severity: "error",
              message: "An Error Occurred Try Again Later!",
            })
          : await setNext(true);
        return;
      case 2:
        let res2 = await setUserPlan({
          userid: userId,
          plan: tiers[plan].title,
        });
        res2 == 400
          ? snack({
              mode: true,
              severity: "error",
              message: "An Error Occurred Try Again Later!",
            })
          : await setNext(true);
        return;

      default:
        break;
    }
  }

  async function setUserPlan(data) {
    try {
      const result = await axios.post(
        "http://localhost:3224/signup/client_plan",
        data
      );
      const response = result.status;
      return response;
    } catch (err) {
      console.log(err);
      return 400;
    }
  }

  return (
    <>
      <Card sx={{ overflowY: "scroll" }}>
        <CardContent>
          <Grid container spacing={1} alignItems="center">
            {tiers.map((tier, index) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === "VIP" ? 12 : 6}
                md={4}
                onClick={() => {
                  selectPlan(index);
                }}
              >
                {activePlan === index ? (
                  <Border
                    borderRadius="10px"
                    borderPixel="2px"
                    borderStyle="solid"
                    borderColor="skyblue"
                  >
                    <PlansCard
                      title={tier.title}
                      subheader={tier.subheader}
                      price={tier.price}
                      description={tier.description}
                      buttonVariant={tier.buttonVariant}
                      buttonText="SELECTED"
                    />
                  </Border>
                ) : (
                  <PlansCard
                    title={tier.title}
                    subheader={tier.subheader}
                    price={tier.price}
                    description={tier.description}
                    buttonVariant={tier.buttonVariant}
                    buttonText={tier.buttonText}
                  />
                )}
              </Grid>
            ))}
          </Grid>
          <CardActions>
            <button
              ref={submitRef}
              style={{ display: "none" }}
              onClick={() => {
                setPlan(activePlan);
              }}
            />
          </CardActions>
        </CardContent>
      </Card>
    </>
  );
}

export default UserMembershipPlan;
