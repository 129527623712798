const EMAIL_ERROR_MESSAGE = "User with the same Email exists"
const USERNAME_ERROR_MESSAGE = "User with the same Username exists"
const  AGE_ERROR_MESSAGE = "Age Not Accepted"
const PWD_ERR_MSG_1 = "Password must be a least 6 characters long"
const PWD_ERR_MSG_2= "Passwords do not match"
const FORM_SUBMITTED = "done"

module.exports ={
    EMAIL_ERROR_MESSAGE,
    USERNAME_ERROR_MESSAGE,
    AGE_ERROR_MESSAGE,
    PWD_ERR_MSG_1,
    PWD_ERR_MSG_2,
    FORM_SUBMITTED,
}