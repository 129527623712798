import React, { useEffect, useRef, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import {
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Card,
  Snackbar,
  Alert,
} from "@mui/material";
import { Container } from "../../styles/index";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import UserBasicDetails from "./SignUpForms/UserBasicDetails";
import UserMembershipPlan from "./SignUpForms/UserMembershipPlan";
import UserBillingInfo from "./SignUpForms/UserBillingInfo";
import UserSignupCompleted from "./SignUpForms/UserSignupCompleted";
import UserPaymentMethod from "./SignUpForms/UserPaymentMethod";
import { useHistory } from "react-router-dom";
import UserFormContext, {
  UserAdvanceDetails,
} from "../context/user/UserAdvanceDetail";
import styled from "styled-components";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://louixani.com/">
        louixani
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

// CODINGS FOR STEPPER STARTS HERE
const steps = [
  "Basic Information",
  "Select Membership",
  "Payment Method",
  "Billing Info",
  "Complete",
];

function getStepContent(
  step,
  ref,
  next,
  skip,
  userId,
  setUserId,
  setNextButtonState,
  setOpenSnack
) {
  console.log(step, "steppeo");
  switch (step) {
    case 0:
      return (
        <UserBasicDetails
          submitRef={ref}
          setNext={next}
          setUserId={setUserId}
          nextButton={setNextButtonState}
          snack={setOpenSnack}
        />
      );
    case 1:
      return (
        <UserMembershipPlan
          submitRef={ref}
          setNext={next}
          skipToEnd={skip}
          userId={userId}
          nextButton={setNextButtonState}
          snack={setOpenSnack}
        />
      );
    case 2:
      return (
        <UserPaymentMethod
          submitRef={ref}
          setNext={next}
          nextButton={setNextButtonState}
          snack={setOpenSnack}
          username={userId}
        />
      );
    case 3:
      return (
        <UserBillingInfo
          submitRef={ref}
          nextButton={setNextButtonState}
          username={userId}
          snack={setOpenSnack}
          setNext={next}
        />
      );
    case 4:
      return <UserSignupCompleted />;
    default:
      throw new Error("Unknown step", step);
  }
}
//   STEPPER CODINGS ENDS HERE

export default function SignUp() {
  // THE STEPPER FUNCTIONS CONTD
  let submitRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [next, setNext] = useState(false);
  const [nextButtonState, setNextButtonState] = useState(true);
  const [userId, setUserId] = useState("");
  const history = useHistory();

  const isStepOptional = (step) => {
    return step === null; //replace null with the optional step value
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  useEffect(() => {
    console.log(skipped);
  }, []);

  //   THIS FUNCTION GETS THE COMPONENT FORM ACTION WITH SWITCH CASE AND TRIGGERS IT
  async function handleNext(step) {
    switch (step) {
      case 0:
        await submitRef.current.click();
        console.log(next, "next value");
        return;
      case 1:
        await submitRef.current.click();
        console.log("step", step);
        break;
        return;
      case 2:
        console.log(submitRef);
        await submitRef.current.click();
        console.log("step", step);
        return;
      case 3:
        console.log("step", step);
        await submitRef.current.click();
        return;
      case 4:
        console.log("step", step);
        completed();
        return;
      default:
        throw new Error("Unknown step");
    }
  }

  //   THIS FUNCTION GETS CALLED IF THE FINAL STEP IS MET
  function completed() {
    if (activeStep == steps.length - 1) {
      console.log("yess");
      history.push("/");
    }
  }
  //   THIS STATEMENT SETS OR RATHER INCREMENTS THE STEPPER
  if (next) {
    setNext(!next);
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    console.log(activeStep, "next func");
    console.log(userId, "next func");

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }

  //  FOR THE STEPPER BACK BUTTON
  //   const handleBack = () => {
  //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
  //   };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const skipToEnd = () => {
    var lastStep = steps.length - activeStep - 1;
    setActiveStep((prevActiveStep) => prevActiveStep + lastStep);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(lastStep);
      return newSkipped;
    });
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  //   ENDS HERE

  // THIS FUNCTION TRIGGERS THE SNACKBAR FOR USER FEEDBACK
  const [openSnack, setOpenSnack] = useState({
    mode: false,
    message: "",
    severity: "",
  });

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(openSnack.mode, false);
  };

  return (
    <UserFormContext>
      <ThemeProvider theme={theme}>
        <Container>
          <CssBaseline />
          <Card
            variant="elevation"
            sx={{
              width: {
                xs: 300,
                sm: 400,
                md: 700,
              },
            }}
          >
            {/* This snackbar display error or success messages */}
            <Snackbar
              open={openSnack.mode}
              autoHideDuration={3000}
              onClose={handleSnackClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleSnackClose}
                severity={openSnack.severity}
                sx={{ width: "100%" }}
              >
                {openSnack.message}
              </Alert>
            </Snackbar>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
              variant="h6"
            >
              Create a louixani account
            </Typography>
            <Stepper sx={{ position: "relative" }} activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    {/* {console.log(label, activeStep)} */}
                    {activeStep == index ? (
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    ) : (
                      <StepLabel></StepLabel>
                    )}
                  </Step>
                );
              })}
            </Stepper>
            {/* <Spacer /> */}
            <Box
              sx={{
                width: "100%",
                height: "50vh",
                overflow: "scroll",
                overflowX: "hidden",
                overflowY: "hidden",
              }}
              position="relative"
            >
              {activeStep === steps.length - 1 ? (
                <Box
                  maxWidth="xs"
                  sx={{
                    display: "flex",
                    height: "inherit",
                    width: "inherit",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <UserSignupCompleted />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "inherit",
                    height: "inherit",
                    maxHeight: "inherit",
                  }}
                >
                  {/* STEPPERR VIEW MAIN CONTENTS */}
                  <Box
                    sx={{ width: "inherit", height: "inherit" }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                  >
                    {getStepContent(
                      activeStep,
                      submitRef,
                      setNext,
                      skipToEnd,
                      userId,
                      setUserId,
                      setNextButtonState,
                      setOpenSnack
                    )}
                  </Box>
                </Box>
              )}
            </Box>
            {/* STEPPER ACTION BUTTONS */}
            <Box sx={{ display: "flex", flexDirection: "row", p: 2 }}>
              {/* I COMMENTED THE BACK BUTTON OUT BECAUSE I WOULD HAVE TO REUSE STATES TO STORE THE USER INPITED VALUES
            WHICH I SEE AS A PROMOTION OF LAGGING AND I DONT WANT THAT */}
              {/* <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1, display: activeStep === 0 ? "none" : "block" }}
            >
              {activeStep === steps.length - 1 ? "" : "Back"}
            </Button> */}
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
              <Box sx={{ flex: "auto" }}>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Box>

              <Button
                // style={{ backgroundColor: "blue", color: "white" }}
                onClick={() => {
                  handleNext(activeStep);
                }}
                variant="contained"
                disabled={nextButtonState}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
            <Copyright sx={{ mt: 2 }} />
          </Card>
        </Container>
      </ThemeProvider>
    </UserFormContext>
  );
}
