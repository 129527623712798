import React, { createContext, useState } from 'react'
const UserAdvanceDetails = createContext([])

export default function UserFormContext({children}) {
    const [username, setUsername] = useState("");
    const [clientPlan, setClientPlan] = useState("")

    return (
        <UserAdvanceDetails.Provider value={{username, setUsername, clientPlan, setClientPlan}}>
            {children}
        </UserAdvanceDetails.Provider>
    )
}

export{
    UserAdvanceDetails
}