import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthRoutes from "./config/AuthRoutes";
import Routes from "./config/Routes";
import NotFound from './pages/Errors/NotFound'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/error" component={NotFound} />
        <Route path="/(signup)" exact component={AuthRoutes} />
        <Route path="/(login)" exact component={AuthRoutes} />
        <Route path="/" component={Routes} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
