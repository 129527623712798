import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  TextField,
  Card,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import DateComponent from "../components/DateComponent";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form"; //Controller
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import {
  AGE_ERROR_MESSAGE,
  EMAIL_ERROR_MESSAGE,
  FORM_SUBMITTED,
  PWD_ERR_MSG_1,
  PWD_ERR_MSG_2,
  USERNAME_ERROR_MESSAGE,
} from "../../../helpers/constants";
import { UserAdvanceDetails } from "../../context/user/UserAdvanceDetail";

const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

function UserBasicDetails({
  submitRef,
  setNext,
  setUserId,
  nextButton,
  snack,
}) {
  // Activating Next BUTTON
  nextButton(false);
  // ends

  const [country, setCountry] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [ageError, setAgeError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      // setUsernameError(!usernameError)
    }, 1000);
  }, [usernameError, emailError]);

  const handleChange = (event) => {
    setCountry(event.target.value);
  };

  //   SUBMIT AND FORM VALIDATION STARTS
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("FirstName is required"),
    lastname: Yup.string().required("LastName is required"),
    username: Yup.string()
      .required("Username is required")
      .min(6, "Username must be at least 6 characters")
      .max(20, "Username must not exceed 20 characters"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    dob: Yup.string().required("Age is required"),
    country: Yup.string()
      .required("Choose Your Country")
      //   .oneOf(["usa", "mexico"])
      .label("Selected Country"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
    // acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    console.log(JSON.stringify(data, null, 2));
    const result = await axios.post("http://localhost:3224/signup", data);
    const response = result.data.response;

    // I DIDNT USE STATUS CODE FOR THE SERVCER ERROE HANDLING INSTEAD 
    // I USED THE SERVERE RESPOMNSES SOS AS TO TRIGGER THE CORRESPONDING 
    // ERROR PARAMETERS EG USRNAME OR EMAILS

    if (response == USERNAME_ERROR_MESSAGE) {
      setUsernameError(true);
      setTimeout(() => {
        setUsernameError(false);
      }, 5000);
      snack({
        mode: true,
        severity: "error",
        message: "Username Error",
      });
    } else if (response == EMAIL_ERROR_MESSAGE) {
      setEmailError(true);
      setTimeout(() => {
        setEmailError(false);
      }, 5000);
      snack({
        mode: true,
        severity: "error",
        message: "Email Error",
      });
    } else if (response == AGE_ERROR_MESSAGE) {
      setAgeError(true);
      setTimeout(() => {
        setAgeError(false);
      }, 5000);
      snack({
        mode: true,
        severity: "error",
        message: AGE_ERROR_MESSAGE,
      });
    } else if (response == PWD_ERR_MSG_1 || response == PWD_ERR_MSG_2) {
      snack({
        mode: true,
        severity: "error",
        message: response,
      });
    } else if (response == FORM_SUBMITTED) {
      console.log(response);
      setUserId(data.username);
      await setNext(true);
    }
  };
  //   SUBMIT AND FORM VALIDATION ENDS

  function serverError(errorState, errorMessage) {
    if (errorState) {
      return <Typography sx={{ color: "red" }}>{errorMessage}</Typography>;
    }
  }

  return (
    <Card
      style={{ boxShadow: "none", overflow: "scroll", overflowX: "hidden" }}
    >
      <CardContent>
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Basic Information
          </Typography>
          {/* SERVER ERROR NOTIFICATION DISPLAY */}
          {/* {usernameError ? (
            <Typography sx={{ color: "red" }}>Username Error</Typography>
          ) : emailError ? (
            <Typography sx={{ color: "red" }}>Email Error</Typography>
          ) : (
            ""
          )} */}{" "}
          {/* I USED SNACKBAR  INSTEAD */}
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              "& .MuiInputBase-input": {
                m: 1,
                height: "5ch",
                width: {
                  sm: "35ch",
                  md: "40ch",
                  xs: "30ch",
                },
              },
              "& > :not(style)": {
                m: 1,
                width: {
                  sm: "35ch",
                  md: "40ch",
                  xs: "30ch",
                },
              },
              "& .MuiInputBase-input::after": {
                color: "red",
                borderBottom: "2px solid red",

                "&focus": {
                  color: "pink",
                  borderBottom: "2px solid red",
                },
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstname"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  autoFocus
                  helperText={errors.firstname?.message}
                  {...register("firstname")}
                  error={errors.firstname ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  autoComplete="family-name"
                  helperText={errors.lastname?.message}
                  {...register("lastname")}
                  error={errors.lastname ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  size=""
                  id="username"
                  label="User Name"
                  name="username"
                  autoComplete="username"
                  helperText={errors.username?.message}
                  {...register("username")}
                  error={errors.username ? true : false}
                />
                {serverError(usernameError, USERNAME_ERROR_MESSAGE)}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  size=""
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  helperText={errors.email?.message}
                  {...register("email")}
                  error={errors.email ? true : false}
                />
                {serverError(emailError, EMAIL_ERROR_MESSAGE)}
              </Grid>
              <Grid item xs={12}>
                <DateComponent errors={errors} register={register} />
                {serverError(ageError, AGE_ERROR_MESSAGE)}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  "& .MuiSelect-select": {
                    // m: 0,
                    height: "5ch",
                    padding: 0,
                    width: {
                      sm: "31.5ch",
                      md: "36.5ch",
                      xs: "26.5ch",
                    },
                  },
                }}
              >
                <FormControl
                  error={errors.country ? true : false}
                  {...register("country")}
                >
                  <InputLabel id="country">Country Of Resident</InputLabel>
                  <Select
                    labelId="country"
                    id="country"
                    name="country"
                    value={country}
                    onChange={handleChange}
                    // fullWidth
                    label="Country Of Resident"
                  >
                    <MenuItem value="">
                      <em>Choose Your Country</em>
                    </MenuItem>
                    {countryList.map((country, index) => (
                      <MenuItem key={index} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.country?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  helperText={errors.password?.message}
                  {...register("password")}
                  error={errors.password ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  helperText={errors.confirmPassword?.message}
                  {...register("confirmPassword")}
                  error={errors.confirmPassword ? true : false}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
            </Grid>
            <CardActions>
              <button
                ref={submitRef}
                type="submit"
                style={{ display: "none" }}
              />
            </CardActions>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default UserBasicDetails;
