import React, { useEffect } from "react";
import {
  Typography,
  Container,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Card,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";

function UserSignupCompleted() {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push("/login");
    }, 3000);
  }, []);

  return (
    <Card
      sx={{
        display: "flex",
        padding: "20px",
        maxWidth: {
          sm: "35ch",
          md: "40ch",
          xs: "30ch",
        },
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "lightblue",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mt: 2,
          mb: 1,
        }}
      >
        Your Account has been created successfully and you'll be redirected if
        not click <Link to={"/login"}>here</Link>
      </Typography>
      {/* <Button
        href="/"
        sx={{
          backgroundColor: "blue",
          borderRadius: "25px",
          p: 1,
          color: "white",
          ":hover": { backgroundColor: "skyblue" },
        }}
      >
        GO TO HOME
      </Button> */}
    </Card>
  );
}

export default UserSignupCompleted;
