import React from "react";
import {
  Card,
  CardContent,
  Container,
  CardHeader,
  Box,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import { StarBorderOutlined as StarIcon } from "@mui/icons-material";

function PlansCard({
  title,
  subheader,
  price,
  description,
  buttonText,
  buttonVariant,
}) {
  return (
    <>
      <Container disableGutters maxWidth="md" component="main">
        <Card
          sx={{
            cursor: "pointer",
          }}
        >
          <CardHeader
            title={title}
            subheader={subheader}
            titleTypographyProps={{ align: "center" }}
            action={title === "Pro" ? <StarIcon /> : null}
            subheaderTypographyProps={{
              align: "center",
            }}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[200]
                  : theme.palette.grey[700],
            }}
          />
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
                mb: 2,
              }}
            >
              <Typography component="h2" variant="h3" color="text.primary">
                ${price}
              </Typography>
              <Typography variant="h6" color="text.secondary">
                /mo
              </Typography>
            </Box>
            <ul>
              {description.map((line) => (
                <Typography
                  component="li"
                  variant="subtitle2"
                  align="center"
                  key={line}
                  sx={{ fontWeight: "normal", padding: "5px" }}
                >
                  {line}
                  <hr />
                </Typography>
              ))}
            </ul>
          </CardContent>
          <CardActions>
            <Button fullWidth variant={buttonVariant}>
              {buttonText}
            </Button>
          </CardActions>
        </Card>
      </Container>
    </>
  );
}

export default PlansCard;
