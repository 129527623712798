import React from 'react'
import { Redirect, Route, Switch } from "react-router-dom";
import Home from '../pages/Home'

function Routes() {
  return (
    <div>
         <Switch>
          <Route exact path="/" component={Home} />
          <Redirect to="/error" />
        </Switch>
    </div>
  )
}

export default Routes