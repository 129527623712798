import { Box, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import styled from "styled-components";

// TRIED TO USE THE FORM HOOK FOR VALIDATION BUT EXPERIENCING ERROR DUE TO REACT CREDIT CARD STATE UPDATES
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";

const CardWrapper = styled.div`
  @media screen and (max-width: 600px) {
    .rccs__card {
      width: 200px;
      height: 130px;
    }
    .rccs .rccs__card div {
      font-size: 10px !important;
    }
  }
`;

function CreditCardForm({
  submitRef,
  setNext,
  nextButton,
  username,
  snack,
  paymentDetail,
  setPaymentDetail,
}) {
  const [data, setData] = useState({
    cvc: "",
    expiry: "",
    name: "",
    number: "",
  });
  const [errors, setErrors] = useState({
    cvc: "",
    expiry: "",
    name: "",
    number: "",
  });
  const [format, setFormat] = useState(true);
  const [haveBeenFormatted, setHaveBeenFormatted] = useState(false);
  const [exceeded, setExceeded] = useState(false);
  const formatInput = (e) => {
    console.log(format, haveBeenFormatted, exceeded);

    if (e.target.value.includes("/") && e.target.value.charAt(2) !== "/") {
      e.target.value = e.target.value.replace("/", "");
      e.target.value = refactor(e.target.value);
    }
    if (e.target.value.length === 3 && e.target.value.includes("/") === false) {
      e.target.value = refactor(e.target.value);
    }
    if (e.target.value.length > 3 && e.target.value.includes("/") === false) {
      e.target.value = refactor(e.target.value);
    }
    if (haveBeenFormatted && e.target.value.length === 1) {
      setFormat(true);
      setExceeded(false);
    }
    if (format && !exceeded && e.target.value.length === 2) {
      e.target.value = e.target.value += "/";
      setHaveBeenFormatted(true);
      setFormat(false);
    }
    if (exceeded && e.target.value.length === 3) {
      e.target.value = e.target.value.replace("/", "");
    }
    if (!exceeded && e.target.value.length === 4) {
      setExceeded(true);
    }
    function refactor(str) {
      if (str.length === 3) {
        let splittedString = str.charAt(2);
        let currentString = str.substring(0, 2);
        let refactoredString = concat(currentString, splittedString);
        return refactoredString;
      } else if (str.length >= 4) {
        str = str.substring(0, 4);
        let firstStr = str.substring(0, 2);
        let secondStr = str.substring(2, 4);
        return concat(firstStr, secondStr);
      }
      function concat(str1, str2) {
        return str1 + "/" + str2;
      }
    }
    // console.log(e.target.value);
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  //   SUBMIT AND FORM VALIDATION STARTS
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (!data.number) {
      setErrors({ number: "Card Number required" });
      inpErr();
    } else if (data.number.length < 16) {
      setErrors({ number: "Please input a valid Card Number" });
      inpErr();
    } else if (!data.name) {
      setErrors({ name: "Card Name required" });
      inpErr();
    } else if (!data.expiry) {
      setErrors({ expiry: "Card Expiry required" });
      inpErr();
    } else if (/[a-zA-Z]/g.test(data.expiry) || data.expiry.length < 5) {
      setErrors({ expiry: "Invalid Card Expiry Date" });
      inpErr();
    } else if (!data.cvc) {
      setErrors({ cvc: "Card CVC required" });
      inpErr();
    } else if (data.cvc.length < 3) {
      setErrors({ cvc: "Input a valid CVC" });
      inpErr();
    } else {
      setErrors({
        cvc: "",
        expiry: "",
        name: "",
        number: "",
      });
      setValidated(true);
      nextButton(false);
    }
  }, [data]);

  function inpErr() {
    setValidated(false);
    nextButton(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    data.username = username
    console.log(JSON.stringify(data, null, 2));
    const res = await addUserCreditCard(data);
    res == 400
      ? snack({ mode: true, severity: "error", message: "An Error Occured!" })
      : await setNext(true);
  };

  async function addUserCreditCard(data) {
    try {
      const result = await axios.post(
        "http://localhost:3224/signup/creditcard",
        data
      );
      const response = result.status;
      return response;
    } catch (err) {
      console.log(err);
      return 400;
    }
  }
  //   SUBMIT AND FORM VALIDATION ENDS

  // showing card image when detail is inserted
  const [showCard, setShowCard] = useState(false);
  useEffect(() => {
    if (data.number.length >= 3) {
      setShowCard(true);
    } else {
      setShowCard(false);
    }
  }, [data]);

  return (
    <Box
      component="form"
      noValidate
      onSubmit={(e) => {
        handleSubmit(e);
      }}
      sx={{
        width: {
          sm: "35ch",
          md: "40ch",
          xs: "30ch",
          "& .MuiInputBase-input": {
            m: 1,
            height: "5ch",
            width: {
              sm: "35ch",
              md: "40ch",
              xs: "30ch",
            },
          },
        },
      }}
      display="flex"
      flexDirection="column"
      justifyContent={"center"}
      alignItems={"center"}
      gap="10px"
      marginTop="20px"
    >
      {showCard ? (
        <CardWrapper>
          <Cards
            cvc={data.cvc}
            expiry={data.expiry}
            focus={data.focus}
            name={data.name}
            number={data.number}
          />
        </CardWrapper>
      ) : (
        ""
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            name="number"
            label="Card Number"
            onInput={(e) => {
              let input = e.target.value.length;
              if (input > 16) {
                e.target.value = e.target.value.substring(0, input - 1);
              }
            }}
            variant="filled"
            onChange={handleChange}
            helperText={errors.number}
            error={errors.number ? true : false}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            name="name"
            label="Card Holder Name"
            variant="filled"
            onChange={handleChange}
            helperText={errors.name}
            error={errors.name ? true : false}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            // inputProps={{ maxLength: 5 }}
            onInput={(e) => {
              let input = e.target.value.length;
              if (input > 5) {
                e.target.value = e.target.value.substring(0, input - 1);
              }
            }}
            name="expiry"
            label="Expires"
            variant="filled"
            onChange={(e) => {
              handleChange(e);
              formatInput(e);
            }}
            helperText={errors.expiry}
            error={errors.expiry ? true : false}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="number"
            name="cvc"
            label="CVC"
            variant="filled"
            onInput={(e) => {
              let input = e.target.value.length;
              if (input > 4) {
                e.target.value = e.target.value.substring(0, input - 1);
              }
            }}
            onChange={handleChange}
            helperText={errors.cvc}
            error={errors.cvc ? true : false}
          />
        </Grid>
        <button ref={submitRef} type="submit" style={{ display: "none" }} />
      </Grid>
    </Box>
  );
}

export default CreditCardForm;
