import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Card,
  CardContent,
  Box,
  CardActions,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";

export default function UserBillingInfo({
  submitRef,
  nextButton,
  username,
  setNext,
  snack
}) {
  // THE NEXTBUTTON
  nextButton(false);

  // COUNTRY SELECT FUNCTIONS
  const [country, setCountry] = useState("");
  const countryList = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands",
  ];
  const handleChange = (event) => {
    setCountry(event.target.value);
  };
  // ==========ENDS HERE==========

  // Checkbox
  const [check, setCheck] = useState(false);
  function handleCheck() {
    setCheck(!check);
  }
  // ===========Ends Here===========

  //   SUBMIT AND FORM VALIDATION STARTS
  const validationSchema = Yup.object().shape({
    address1: Yup.string()
      .required("Input Your Address")
      .min(6, "Please input a valid address"),
    city: Yup.string().required("City required"),
    state: Yup.string().required("State/Province Required"),
    zip: Yup.string()
      .required("Zip Code required")
      .max(20, "Please input a valid address"),
    country: Yup.string().required("Country Required"),
    checkbox: Yup.boolean().oneOf(
      [true],
      "Address must be your payment address"
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    data.username = username; // This adds the username object to the data json
    console.log(JSON.stringify(data, null, 2));
    const res = await addBillingInfo(data);
    if (res == 200) {
      // Note: snack is the mui component i refactored in signup.js so as to display messages
      // I passed the snackbar state, type and message across.
      snack({ mode: true, severity: "success", message: "Done!" });
      await setNext(true);
    } else if (res == 400) {
      snack({ mode: true, severity: "error", message: "An Error Occured!" });
    }
  };
  // Posting formdata to the server
  async function addBillingInfo(data) {
    try {
      const res = await axios.post(
        "http://localhost:3224/signup/billing_info",
        data
      );
      return res.status;
    } catch (err) {
      return 400;
    }
  }
  //   SUBMIT AND FORM VALIDATION ENDS

  return (
    <Box
      sx={{
        display: "flex",
        height: "inherit",
        width: "inherit",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
      }}
    >
      <Typography variant="h6" gutterBottom mt={2}>
        Billing Address
      </Typography>
      <Card
        elevation={0}
        sx={{
          width: {
            xs: 300,
            md: 500,
          },
        }}
        style={{ overflow: "scroll", overflowX: "hidden" }}
      >
        <CardContent>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              "& .MuiInputBase-input": {
                // m: 1,
                height: "5ch",
                width: {
                  sm: "35ch",
                  md: "40ch",
                  xs: "30ch",
                },
              },
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="address1"
                  name="address1"
                  label="Address line 1"
                  fullWidth
                  autoComplete="shipping address-line1"
                  variant="standard"
                  helperText={errors.address1?.message}
                  {...register("address1")}
                  error={errors.address1 ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address2"
                  name="address2"
                  label="Address line 2 (Optional)"
                  fullWidth
                  autoComplete="shipping address-line2"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="city"
                  name="city"
                  label="City"
                  fullWidth
                  autoComplete="shipping address-level2"
                  variant="standard"
                  helperText={errors.city?.message}
                  {...register("city")}
                  error={errors.city ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="state"
                  name="state"
                  label="State/Province"
                  fullWidth
                  variant="standard"
                  helperText={errors.state?.message}
                  {...register("state")}
                  error={errors.state ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="zip"
                  name="zip"
                  label="Zip / Postal code"
                  fullWidth
                  autoComplete="shipping postal-code"
                  variant="standard"
                  helperText={errors.zip?.message}
                  {...register("zip")}
                  error={errors.zip ? true : false}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  "& .MuiSelect-select": {
                    m: 0.7,
                    height: "5ch",
                    // padding: 0,
                    // width: {
                    //   sm: "31.5ch",
                    //   md: "36.5ch",
                    //   xs: "26.5ch",
                    // },
                  },
                }}
              >
                <FormControl
                  fullWidth
                  variant="standard"
                  error={errors.country ? true : false}
                  {...register("country")}
                >
                  <InputLabel id="country">Country</InputLabel>
                  <Select
                    labelId="country"
                    id="country"
                    value={country}
                    name="country"
                    onChange={handleChange}
                    label="Country"
                    // {...register("country")}
                  >
                    <MenuItem value="">
                      <em>Choose Your Country</em>
                    </MenuItem>
                    {countryList.map((country, index) => (
                      <MenuItem key={index} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.country?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  error={errors.checkbox ? true : false}
                  {...register("checkbox")}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        id="checkbox"
                        name="checkbox"
                        value={check}
                        onChange={() => {
                          handleCheck();
                        }}
                      />
                    }
                    label="Use this address for payment details"
                  />
                  <FormHelperText>{errors.checkbox?.message}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <CardActions>
              <button
                ref={submitRef}
                type="submit"
                style={{ display: "none" }}
              />
            </CardActions>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
