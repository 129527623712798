import React from "react";
import { Box, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";

function SBForm({
  submitRef,
  setNext,
  nextButton,
  snack,
  username,
  paymentDetail,
  setPaymentDetail,
}) {
  //   SUBMIT AND FORM VALIDATION STARTS
  const validationSchema = Yup.object().shape({
    sb_wallet_id: Yup.string()
      .required("Input Your Wallet ID")
      .min(12, "Please input a valid Wallet ID")
      .max(12, "Invalid Wallet ID")
      // THIS TEST FUNCION BELOW ALLOWS CUSTOM FUNC TO BE CALLED IN YUP
      .test("sb_wallet_id", null, function (value) {
        value.length === 12 ? nextButton(false) : nextButton(true);
        return true;
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    data.username = username
    console.log(JSON.stringify(data, null, 2));
    const res = await addUserSB_Id(data);
    res == 400
      ? snack({ mode: true, severity: "error", message: "An Error Occured!" })
      : await setNext(true);
    // await setNext(true);
  };

  async function addUserSB_Id(data) {
    try {
      const result = await axios.post(
        "http://localhost:3224/signup/sb_wallet",
        data
      );
      const response = result.status;
      return response;
    } catch (err) {
      console.log(err);
      return 400;
    }
  }
  //   SUBMIT AND FORM VALIDATION ENDS

  return (
    <Box
      sx={{
        display: "flex",
        height: "200px",
        width: "inherit",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",

        "& .MuiInputBase-input": {
          m: 1,
          height: "5ch",
          width: {
            sm: "35ch",
            md: "40ch",
            xs: "25ch",
          },
        },
      }}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        required
        id="sb_wallet_id"
        name="sb_wallet_id"
        label="Wallet ID"
        fullWidth
        autoComplete="card number"
        variant="filled"
        helperText={errors.sb_wallet_id?.message}
        {...register("sb_wallet_id")}
        error={errors.sb_wallet_id ? true : false}
      />
      <button ref={submitRef} type="submit" style={{ display: "none" }} />
    </Box>
  );
}

export default SBForm;
